<template>
    <div class="p-8 text-xs">
        <button @click="$router.push('/users')" class="flex items-center text-blue-500">
            <!-- Font Awesome for "chevron-left" -->
            <i class="fas fa-chevron-left w-6 h-6 mb-2"></i>
            <span class="ml-2 mb-5">Kembali</span>
        </button>
        <div class="rounded-2xl bg-white p-8">

            <div class="">
                <div class="">
                    <span class="font-semibold text-base">Tambah Pengguna</span>
                </div>
                <div class="mt-4 grid grid-cols-12 gap-4">
                    <!-- <div class="col-span-3">
                           <div class="flex justify-center border border-gray-200">
                               <div class="w-40 h-40 ">
                                   <img :src="default_url + 'default.png'" class="w-40 h-40 object-cover " @click="$refs.foto_profile.click()"/>

                               </div>
                           </div>
                       </div> -->
                    <div class="col-span-12">

                        <ValidationObserver v-slot="{ invalid }" ref="form">
                            <form @submit.prevent="postData()" class="w-full">

                                <ValidationProvider rules="required" v-slot="{ errors }" name="Nama" vid="name">
                                    <label for="" class="block mb-2">Nama</label>
                                    <input v-model="addData.name" type="text"
                                        :class="`block border border-gray-200 w-full p-2 px-4 rounded text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ' mb-4'}`"
                                        name="name" ref="name" id="name" placeholder="Nama" />
                                    <span class="text-red-600 text-xs mb-4">{{ errors[0] }}</span>

                                </ValidationProvider>
                                <ValidationProvider rules="required|email" v-slot="{ errors }" name="Email" vid="email">
                                    <label for="" class="block mb-2">Email</label>
                                    <input v-model="addData.email" type="text"
                                        :class="`block border border-gray-200 w-full p-2 px-4 rounded text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ' mb-4'}`"
                                        name="email" ref="email" placeholder="Email" />
                                    <span class="text-red-600 text-xs mb-4">{{ errors[0] }}</span>

                                </ValidationProvider>
                                <ValidationProvider rules="required" v-slot="{ errors }" name="username" vid="username">
                                    <label for="" class="block mb-2">Username</label>
                                    <input v-model="addData.username" type="text"
                                        :class="`block border border-gray-200 w-full p-2 px-4 rounded text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ' mb-4'}`"
                                        name="username" ref="username" placeholder="username" />
                                    <span class="text-red-600 text-xs mb-4">{{ errors[0] }}</span>

                                </ValidationProvider>
                                <ValidationProvider rules="required" v-slot="{ errors }" name="Hak Akses" vid="role_id">
                                    <label for="" class="block mb-2">Hak Akses</label>
                                    <select ref="role_id" name="role_id"
                                        class="block border border-gray-200 w-full p-2 px-4 rounded text-xs"
                                        @click="changeRole" v-model="addData.role_id">
                                        <option :value="item.id"
                                            class="block border border-gray-200 w-full p-2 px-4 rounded text-xs"
                                            v-for="item in role" :key="item.id">{{ item.name }}</option>


                                    </select>
                                    <span class="text-red-600 text-xs mb-4">{{ errors[0] }}</span>

                                </ValidationProvider>

                                <ValidationProvider v-slot="{ errors }" name="Group Sensor" vid="group_id">
                                    <label for="" class="block mb-2 mt-2">Group Sensor</label>
                                    <select ref="group_id" name="group_id"
                                        class="block border border-gray-200 w-full p-2 px-4 rounded text-xs"
                                        v-model="addData.group_id">
                                        <option :value="item.id"
                                            class="block border border-gray-200 w-full p-2 px-4 rounded text-xs"
                                            v-for="item in groups" :key="item.id">{{ item.name }}</option>


                                    </select>
                                    <span class="text-red-600 text-xs mb-4">{{ errors[0] }}</span>

                                </ValidationProvider>

                                <ValidationProvider :rules="addData.id ? '' : 'required'" v-slot="{ errors }"
                                    name="Password" vid="password">
                                    <label for="" class="block mb-2 mt-2">Password</label>
                                    <input v-model="addData.password" type="password"
                                        :class="`block border border-gray-200 w-full p-2 px-4 rounded text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ' mb-4'}`"
                                        name="password" ref="password" placeholder="Password" />
                                    <span class="text-red-600 text-xs mb-4">{{ errors[0] }}</span>

                                </ValidationProvider>
                                <div class="mt-4" v-if="user.role_id == 32 || user.role_id == 41">
                                    <table class="table-auto w-full text-xs overflow-x-auto">
                                        <tr class="text-left">
                                            <th class=" text-gray-500 py-2 px-4 border border-gray-100">Name</th>
                                            <th class=" text-gray-500 py-2 px-4 border border-gray-100 text-center">
                                            </th>
                                        </tr>
                                        <tbody v-for="item in menu" :key="item.id">
                                            <tr>
                                                <th class=" text-gray-500 py-2 px-4 border border-gray-100 text-left">
                                                    {{ item.name }}</th>
                                                <th class=" text-gray-500 py-2 px-4 border border-gray-100 text-center">
                                                    <input type="checkbox" name="menu_id[]" v-model="addData.menu_id"
                                                        :value="item.id" id="">
                                                </th>
                                            </tr>
                                            <template v-if="item.Menus.length > 0">
                                                <tr v-for="child in item.Menus" :key="child.id">
                                                    <th
                                                        class=" text-gray-500 py-2 px-6 border border-gray-100 text-left">
                                                        - {{ child.name }}</th>
                                                    <th
                                                        class=" text-gray-500 py-2 px-6 border border-gray-100 text-center">
                                                        <input type="checkbox" name="menu_id[]"
                                                            v-model="addData.menu_id" :value="child.id" id="">
                                                    </th>
                                                </tr>
                                            </template>
                                        </tbody>


                                    </table>
                                </div>


                                <div class="float-right">
                                    <button @click="$router.push('/users')" :class="`text-center py-2 px-8 rounded
                                
                                border border-gray
                                mr-2
                                hover:bg-gray-100
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`">Batal</button>

                                    <button :disabled="invalid" type="submit" :class="`text-center py-2 px-8 rounded
                                text-white
                                hover:bg-blue-900 bg-blue-500
                                disabled:opacity-50
                                mt-4
                                
                                focus:outline-none my-1`">Simpan</button>
                                </div>


                            </form>
                        </ValidationObserver>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapAction, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import moment from 'moment'
export default {
    data: () => ({
        data: [],
        totalRows: 100,
        perPage: 10,
        limit: 5,
        currentPage: 1,
        disabled: false,
        modal_dialog: false,
        keyword: '',
        foto: '',
        menu: [],
        role: [],
        groups: [],
        addData: {
            name: '',
            menu_id: [],
            email: '',
            username: '',
            password: '',
            name: '',
            role_id: '',
            group_id: '',

        },
        delete_id: '',
        modal_edit: false,
        default_url: process.env.VUE_APP_IMAGES
    }),
    mixins: [globalMixin],
    methods: {

        async postData() {

            let param = { ...this.addData }
            if (!this.addData.group_id) {
                delete param.group_id;
            }
            this.axios.post('v1/users', param, this.config)
                .then((ress) => {
                    this.$snack.success({
                        text: ress.data.message,

                    })
                    this.$router.push('/users')
                })
                .catch(err => {
                    if (err.response.status == 400) {
                        this.$refs.form.setErrors(err.response.data.message)
                    }
                })

        },
        changeRole() {
            this.addData.menu_id = []
            if (this.user.role_id == 32 || this.user.role_id == 41) {
                let role_menu = this.role.find(x => x.id == this.addData.role_id)
                role_menu.Menus.forEach(element => {
                    this.addData.menu_id.push(element.id)
                });

            }
        },

    },
    computed: {

    },
    created() {
        this.getRole()
        this.getGroup()
        if (this.user.role_id == 32 || this.user.role_id == 41) {
            this.getMenu()

        }
    },
    components: {
        ValidationProvider, ValidationObserver
    }
}
</script>